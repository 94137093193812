import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Cooking App Template | Cooking App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/cooking-mobile-app/"
    metaDescription="Introducing CookUp!, our mobile cooking app design template. Create your own mobile cooking app design with ease. Try now."
    description="
    h2:CookUp! Our chic cooking app design template for mobile
    <br/>
    Cook up a storm with CookUp!, our stunning and streamlined mobile cooking app design template. CookUp! comes packed with all the ingredients you could possibly need to make a stunning app design. With Uizard, collaboration is also easy. Work effortlessly with a team or work solo if you are worried that too many cooks might spoil the broth.
    <br/>
    h3:Cook up an amazing design, rapidly
    <br/>
    CookUp!'s gorgeous, streamlined simplicity is designed so that you can move your app design idea from <a:https://uizard.io/wireframing/>UI wireframe</a> to prototype as quickly as possible. The template comes with all the must-have screens and components that a 5-star cooking app could possibly need, meaning you could have a cooking app design ready to share with teammates and colleagues in no time!
    <br/>
    h3:You choose the ingredients
    <br/>
    Uizard is all about customization and <a:https://uizard.io/prototyping/>rapid UI prototyping</a>, but that doesn't mean you can't add your own flavor to things. With Uizard's vast customization capabilities, you can add a sprinkle of your own typography, a pinch of your own copy, and a taster of your own branding. Choose your own ingredients now and truly bring your cooking app design to life.
    "
    pages={[
      "A warm and welcoming app landing page design",
      "A simple login screen to streamline user experience, with a clean and clear homepage interface",
      "A handy nav footer bar linking to all of the core pages of your cooking app design together",
      "Smart recipe pages, a dedicated profile section, and search functionality to demonstrate how your future users will find their favorite dishes",
    ]}
    projectCode="lrxaW4E46mFpMa1Xzmma"
    img1={data.image1.childImageSharp}
    img1alt="cooking mobile app design overview"
    img2={data.image2.childImageSharp}
    img2alt="cooking mobile app design homepage"
    img3={data.image3.childImageSharp}
    img3alt="cooking mobile app design search screen"
    img4={data.image4.childImageSharp}
    img4alt="cooking mobile app design recipe screen"
    img5={data.image5.childImageSharp}
    img5alt="cooking mobile app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/cooking-mobile-app/cooking-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/cooking-mobile-app/cooking-app-homepage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/cooking-mobile-app/cooking-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/cooking-mobile-app/cooking-app-recipe.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/cooking-mobile-app/cooking-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
